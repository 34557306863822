import Swal from "sweetalert2";
import { sendDeleteRequest, sendPatchRequest, sendPostRequest } from "./utils";
import { addAlert } from "./alerts";

const vrpPrompt = Swal.mixin({
  customClass: {
    container: "modal",
    popup: "modal__content",
    confirmButton: "btn btn-submit",
    cancelButton: "btn btn-secondary margin-right-10",
  },
  buttonsStyling: false,
  backdrop: "rgba(184, 211, 198, 0.8)",
});

export async function prompt({
  title = gettext("Confirm Action"),
  text = "",
  html = "",
  confirmText = gettext("Confirm"),
  cancelText = gettext("Cancel"),
  input = undefined,
  inputOptions = undefined,
  inputValue = undefined,
  inputLabel = undefined,
  confirmAction = () => {},
  cancelAction = () => {},
  didOpen = () => {},
  preConfirm = () => {},
} = {}) {
  const action = await vrpPrompt.fire({
    title: title,
    text: text,
    html: html,
    width: 600,
    padding: "4rem 2rem",
    showCancelButton: true,
    confirmButtonText: confirmText,
    cancelButtonText: cancelText,
    reverseButtons: true,
    input: input,
    inputOptions: inputOptions,
    inputValue: inputValue,
    inputLabel: inputLabel,
    didOpen,
    preConfirm,
  });

  if (action.isConfirmed) {
    confirmAction(action);
  } else {
    cancelAction(action);
  }
  return action;
}

export function requestPrompt({
  options = {},
  method = null,
  url = null,
  data = null,
  successAction = function (response) {
    if (response.data.message) addAlert("success", response.data.message);
  },
  failedAction = function (response) {
    if (response.data.message) addAlert("error", response.data.message);
  },
}) {
  prompt({
    ...options,
    confirmAction: async function () {
      let func = null;
      switch (method) {
        case "post":
          func = sendPostRequest;
          break;
        case "patch":
          func = sendPatchRequest;
          break;
        case "delete":
          func = sendDeleteRequest;
          break;
        default:
          return;
      }

      try {
        const response = await func(url, data);
        if (response.status === 200) {
          successAction(response);
        } else {
          failedAction(response);
        }
      } catch (error) {
        failedAction(error.response);
      }
    },
  });
}

export function postPrompt({
  options = undefined,
  url = undefined,
  data = undefined,
  successAction = undefined,
  failedAction = undefined,
}) {
  requestPrompt({
    options: options,
    method: "post",
    url: url,
    data: data,
    successAction: successAction,
    failedAction: failedAction,
  });
}

export function patchPrompt({
  options = undefined,
  url = undefined,
  data = undefined,
  successAction = undefined,
  failedAction = undefined,
}) {
  requestPrompt({
    options: options,
    method: "patch",
    url: url,
    data: data,
    successAction: successAction,
    failedAction: failedAction,
  });
}

export function deletePrompt({
  options = undefined,
  url = undefined,
  data = undefined,
  successAction = undefined,
  failedAction = undefined,
}) {
  requestPrompt({
    options: options,
    method: "delete",
    url: url,
    data: data,
    successAction: successAction,
    failedAction: failedAction,
  });
}

export async function customSelectPrompt({
  options = undefined,
  choices = [],
  inputLabel: inputLabel,
}) {
  return await prompt({
    ...options,
    didOpen: function () {
      try {
        const swal2_container = document.getElementById("swal2-html-container");
        const select = document.createElement("select");
        select.id = "swal2-select";
        for (const [key, value] of Object.entries(choices)) {
          const option = document.createElement("option");
          option.value = key;
          option.text = value;
          select.add(option);
        }
        swal2_container.after(select);
        const label = document.createElement("label");
        label.htmlFor = "swal2-select";
        label.textContent = inputLabel;
        label.classList.add("swal2-input-label");
        select.before(label);
        new bundle.choices.Choices(select, {
          allowHTML: true,
          classNames: {
            containerOuter: ["choices margin-left-30"],
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    preConfirm: function () {
      return document.getElementById("swal2-select").value;
    },
  });
}

export async function selectPrompt({
  options = undefined,
  choices = [],
  inputLabel = "",
}) {
  return await prompt({
    ...options,
    input: "select",
    inputOptions: choices,
    inputLabel: inputLabel,
    didOpen: function () {
      try {
        const select = document.querySelector(".swal2-select");
        new bundle.choices.Choices(select, {
          allowHTML: true,
          classNames: {
            containerOuter: ["choices margin-left-30"],
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    preConfirm: function () {
      return document.querySelector(".swal2-select").value;
    },
  });
}

export async function inputPrompt({
  options = undefined,
  choices = [],
  inputValue = "",
  inputLabel = "",
}) {
  return await prompt({
    ...options,
    input: "text",
    inputValue: inputValue,
    inputLabel: inputLabel,
  });
}

export async function textAreaPrompt({
  options = undefined,
  choices = [],
  inputValue = "",
  inputLabel = "",
}) {
  return await prompt({
    ...options,
    input: "textarea",
    inputValue: inputValue,
    inputLabel: inputLabel,
  });
}

export function deleteRowPrompt(url, row) {
  deletePrompt({
    options: {
      title: gettext("Confirmation"),
      html: `${gettext("Are you sure you want to delete this object?")}
               <small>${gettext(
                 "You won't be able to revert this action!",
               )}</small>`,
      confirmText: gettext("Yes, delete it!"),
    },
    url: url,
    successAction: function (response) {
      row.delete();
      if (response.data.message) addAlert("success", response.data.message);
    },
  });
}

export function archiveRowPrompt(url, row) {
  postPrompt({
    options: {
      title: gettext("Confirmation"),
      html: `${gettext("Are you sure you want to archive this object?")}
            <small>${gettext(
              "You will be able to revert this action.",
            )}</small>`,
      confirmText: gettext("Yes, archive it!"),
    },
    url: url,
    successAction: function (response) {
      row.delete();
      if (response.data.message) addAlert("success", response.data.message);
    },
  });
}

export function restoreRowPrompt(url, row) {
  postPrompt({
    options: {
      title: gettext("Confirmation"),
      html: `${gettext("Are you sure you want to restore this object?")}
      <small>${gettext("You will be able to revert this action.")}</small>`,
      confirmText: gettext("Yes, restore it!"),
    },
    url: url,
    successAction: function (response) {
      row.delete();
      if (response.data.message) addAlert("success", response.data.message);
    },
    failedAction: function (response) {
      if (response.data.message) addAlert("error", response.data.message);
    },
  });
}

export default {
  prompt,
  postPrompt,
  patchPrompt,
  deletePrompt,
  selectPrompt,
  customSelectPrompt,
  inputPrompt,
  textAreaPrompt,
  deleteRowPrompt,
  archiveRowPrompt,
  restoreRowPrompt,
};
